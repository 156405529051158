.border-none:before {
  display: none;
}
.border-none:after {
  display: none;
}
.flex-fix {
  -ms-flex: 1 1 0px;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  flex: 1;
  -webkit-flex-basis: 0px;
  flex-basis: 0px;
  width: 1%;
}
.hide-scrollbar::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.allcover {
  position: absolute;
  top: 0;
  right: 0;
}
.center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.ct {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.cl {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
.clear-fix {
  *zoom: 1;
}
.clear-fix:before,
.clear-fix:after {
  content: "";
  display: table;
  clear: both;
  overflow: hidden;
}
.team-activity {
  width: 100%;
  height: 100%;
  position: relative;
}
.team-activity .activity-tip {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #999;
}
.team-activity .home {
  position: fixed;
  top: 0.2rem;
  right: 0.2rem;
}
.team-activity .ceremony {
  width: 100%;
  height: 100%;
  font-size: 0.28rem;
  color: #333;
  background-size: 100% auto;
  display: flex;
  justify-content: center;
  align-items: center;
  letter-spacing: 0.02rem;
  /*引导分享*/
}
.team-activity .ceremony .process {
  width: calc(100% - 0.2rem);
  height: 7.4rem;
  padding-top: 1rem;
}
.team-activity .ceremony .process .process-txt {
  padding: 0 0.6rem;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #F6432F;
  border-radius: 0.16rem;
}
.team-activity .ceremony .process .process-txt .process-one {
  width: 1.2rem;
  font-size: 0.28rem;
  color: #fff;
  text-align: center;
}
.team-activity .ceremony .process .process-txt .process-one .img {
  width: 0.8rem;
  height: 0.8rem;
  margin-bottom: 0.08rem;
}
.team-activity .ceremony .process .ceremony-content {
  border-radius: 0.16rem;
  overflow: hidden;
}
.team-activity .ceremony .process .ceremony-content .yd-tab {
  background: #fff;
}
.team-activity .ceremony .process .ceremony-content .yd-tab /deep/ .yd-tab-active {
  color: #F6432F !important;
  background: #fff;
}
.team-activity .ceremony .process .ceremony-content .yd-tab /deep/ .yd-tab-active::before {
  display: none;
}
.team-activity .ceremony .process .ceremony-content .yd-tab .yd-tab-panel-item {
  height: 6rem;
}
.team-activity .ceremony .process .ceremony-content .yd-tab .yd-tab-panel-item .team-border {
  margin: 0.2rem 0;
  height: 5.5rem;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}
.team-activity .ceremony .process .ceremony-content .yd-tab .yd-tab-panel-item .team-prize {
  margin: 0.3rem 0.2rem 0 0.4rem;
  height: 1.64rem;
  background: url('~@/assets/haiyuansu/prize_bg.png');
  position: relative;
  background-size: 100% 100%;
  display: flex;
  align-items: center;
}
.team-activity .ceremony .process .ceremony-content .yd-tab .yd-tab-panel-item .team-prize .img {
  margin-left: 0.36rem;
  width: 1.44rem;
  height: 1.44rem;
}
.team-activity .ceremony .process .ceremony-content .yd-tab .yd-tab-panel-item .team-prize .img img {
  width: 1.44rem;
  height: 1.44rem;
  display: block;
}
.team-activity .ceremony .process .ceremony-content .yd-tab .yd-tab-panel-item .team-prize .claim {
  font-size: 0.28rem;
  color: #333;
  width: 2.48rem;
  height: 1.44rem;
  margin-left: 0.7rem;
  position: relative;
}
.team-activity .ceremony .process .ceremony-content .yd-tab .yd-tab-panel-item .team-prize .claim .clain-con {
  font-size: 0.24rem;
  margin-top: 0.2rem;
}
.team-activity .ceremony .process .ceremony-content .yd-tab .yd-tab-panel-item .team-prize .claim .clain-con .number {
  color: #F6432F;
  font-size: 0.32rem;
  font-weight: bolder;
}
.team-activity .ceremony .process .ceremony-content .yd-tab .yd-tab-panel-item .team-prize .claim .team-user {
  font-size: 0.24rem;
  color: #F6432F;
  position: absolute;
  bottom: 0;
}
.team-activity .ceremony .process .ceremony-content .yd-tab .yd-tab-panel-item .team-prize .claim .team-user .schedule {
  width: 2.3rem;
  height: 0.06rem;
  background: #ccc;
  margin-top: 0.06rem;
  border-radius: 10%;
}
.team-activity .ceremony .process .ceremony-content .yd-tab .yd-tab-panel-item .team-prize .claim .team-user .schedule .actually-sch {
  height: 100%;
  background: #F6432F;
}
.team-activity .ceremony .process .ceremony-content .yd-tab .yd-tab-panel-item .team-prize .claim .prize-name {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.team-activity .ceremony .process .ceremony-content .yd-tab .yd-tab-panel-item .team-prize .btn .btn-layout {
  width: 1.44rem;
  height: 0.6rem;
  font-size: 0.24rem;
  color: #fff;
  border-radius: 0.64rem;
  background: #F6432F;
  line-height: 0.6rem;
  text-align: center;
}
.team-activity .ceremony .process .ceremony-content .yd-tab .yd-tab-panel-item .team-prize .btn .disabled {
  background: #ccc;
}
.team-activity .ceremony .process .ceremony-content .yd-tab .yd-tab-panel-item .team-prize .btn .failure {
  background: #ccc;
}
.team-activity .ceremony .process .ceremony-content .yd-tab .yd-tab-panel-item .team-prize .end {
  height: 100%;
  width: 1.6rem;
  text-align: right;
}
.team-activity .ceremony .process .ceremony-content .yd-tab .yd-tab-panel-item .team-prize .end .team-end {
  margin-top: 0.1rem;
}
.team-activity .ceremony .process .ceremony-content .yd-tab .yd-tab-panel-item .team-prize .end .team-end img {
  width: 0.8rem;
  height: 0.8rem;
}
.team-activity .ceremony .process .ceremony-content .yd-tab .yd-tab-panel-item .team-prize .end .team-end .detail-team {
  font-size: 0.24rem;
  color: #ccc;
  text-decoration: underline;
  margin-top: 0.3rem;
}
.team-activity .ceremony .process .ceremony-content .yd-tab .yd-tab-panel-item .team-prize .border-icon {
  width: 1.4rem;
  height: 0.74rem;
  font-size: 0.28rem;
  color: #fff;
  position: absolute;
  top: 0;
  left: -0.18rem;
  background: url('~@/assets/haiyuansu/team_icon.png');
  background-size: 100% 100%;
  text-align: center;
  line-height: 0.6rem;
}
.team-activity .ceremony .process .ceremony-content .yd-tab .yd-tab-panel-item .team-prize .border-icon img {
  width: 1.2rem;
  height: 0.74rem;
}
.team-activity .ceremony .process .ceremony-content .yd-tab .yd-tab-panel-item .my-team {
  width: calc(100% - 0.2rem);
  height: 0.6rem;
  position: relative;
  left: 0.1rem;
}
.team-activity .ceremony .process .ceremony-content .yd-tab .yd-tab-panel-item .my-team .eqit {
  font-size: 0.24rem;
  color: #999;
  position: absolute;
  right: 0;
  bottom: 0;
}
.team-activity .ceremony .process .ceremony-content .yd-tab .yd-tab-panel-item .my-team .team-num {
  width: 4rem;
  height: 0.3rem;
  position: absolute;
  text-align: center;
  left: 50%;
  top: 50%;
  margin: -0.15rem 0 0 -2rem;
  font-size: 0.28rem;
  color: #F6432F;
  line-height: 0.6rem;
}
.team-activity .ceremony .process .ceremony-content .yd-tab .yd-tab-panel-item .no-team {
  width: 100%;
  height: 5.72rem;
  line-height: 5.72rem;
  text-align: center;
  color: #999;
}
.team-activity .ceremony .process .ceremony-content .yd-tab .yd-tab-panel-item .detail {
  margin: 0.2rem;
  height: 4.5rem;
  border: 0.02rem dashed #f6432f;
  overflow: auto;
  border-radius: 0.08rem;
}
.team-activity .ceremony .process .ceremony-content .yd-tab .yd-tab-panel-item .detail .detail-title {
  margin-top: 0.2rem;
  overflow: hidden;
}
.team-activity .ceremony .process .ceremony-content .yd-tab .yd-tab-panel-item .detail .detail-title .common {
  float: left;
  text-align: center;
  font-size: 0.28rem;
  color: #333;
}
.team-activity .ceremony .process .ceremony-content .yd-tab .yd-tab-panel-item .detail .detail-title .mobile,
.team-activity .ceremony .process .ceremony-content .yd-tab .yd-tab-panel-item .detail .detail-title .time {
  color: #999;
}
.team-activity .ceremony .process .ceremony-content .yd-tab .yd-tab-panel-item .detail .detail-title .mobile,
.team-activity .ceremony .process .ceremony-content .yd-tab .yd-tab-panel-item .detail .detail-title .kehu-num {
  width: 40%;
}
.team-activity .ceremony .process .ceremony-content .yd-tab .yd-tab-panel-item .detail .detail-title .time,
.team-activity .ceremony .process .ceremony-content .yd-tab .yd-tab-panel-item .detail .detail-title .join-time {
  width: 60%;
}
.team-activity .ceremony .process .ceremony-content .yd-tab .yd-tab-panel-item .active-rule {
  margin: 0 0.2rem;
}
.team-activity .ceremony .process .ceremony-content .yd-tab .yd-tab-panel-item .active-rule .active-time p {
  margin-top: 0.3rem;
}
.team-activity .ceremony .process .ceremony-content .yd-tab .yd-tab-panel-item .active-rule .active-time .time-detail {
  color: #333;
  overflow: auto;
}
.team-activity .ceremony .process .ceremony-content .yd-tab .yd-tab-panel-item .active-rule .active-time .time-num {
  color: #333;
}
.team-activity .ceremony .process .ceremony-content .yd-tab .yd-tab-panel-item .active-rule .active-time .rule-detail {
  width: 100%;
  height: 3.5rem;
  border: 0.02rem dashed #f6432f;
  margin-top: 0.2rem;
  padding: 0.2rem;
  border-radius: 0.08rem;
}
.team-activity .ceremony .process .ceremony-content .yd-tab .yd-tab-panel-item .active-rule .active-time .rule-detail .rule-txt {
  margin: 0;
  overflow: hidden;
  letter-spacing: 0.02rem;
  line-height: 0.4rem;
  color: #999;
  word-break: normal;
}
.team-activity .ceremony .visited-btn {
  width: 100%;
  height: 0.86rem;
  font-size: 0.28rem;
  color: #fff;
  text-align: center;
  line-height: 0.86rem;
  background: #F6432F;
  position: fixed;
  bottom: 0;
  display: flex;
  justify-content: center;
}
.team-activity .ceremony .visited-btn .btn-con {
  display: flex;
  align-items: center;
}
.team-activity .ceremony .visited-btn .btn-con img {
  width: 0.44rem;
  height: 0.44rem;
  margin-right: 0.2rem;
}
.team-activity .ceremony .no-click {
  background: #ccc;
}
.team-activity .ceremony .share-user {
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 0;
  left: 0;
}
.team-activity .ceremony .share-user .visit-user {
  width: 6rem;
  height: 8rem;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -4.5rem 0 0 -3rem;
  background: #fff;
  border-radius: 0.1rem;
}
.team-activity .ceremony .share-user .visit-user .visit-content {
  margin: 0.2rem;
  text-align: center;
}
.team-activity .ceremony .share-user .visit-user .visit-content .visit-title {
  line-height: 0.5rem;
}
.team-activity .ceremony .share-user .visit-user .visit-content .number-list {
  width: 100%;
  height: 4rem;
  border: 0.02rem dashed #f6432f;
  border-radius: 0.2rem;
  overflow: auto;
}
.team-activity .ceremony .share-user .visit-user .visit-content .number-list .detail-title {
  margin-top: 0.2rem;
  overflow: hidden;
}
.team-activity .ceremony .share-user .visit-user .visit-content .number-list .detail-title .common {
  float: left;
  text-align: center;
  font-size: 0.28rem;
  color: #333;
}
.team-activity .ceremony .share-user .visit-user .visit-content .number-list .detail-title .mobile,
.team-activity .ceremony .share-user .visit-user .visit-content .number-list .detail-title .time {
  color: #999;
}
.team-activity .ceremony .share-user .visit-user .visit-content .number-list .detail-title .mobile,
.team-activity .ceremony .share-user .visit-user .visit-content .number-list .detail-title .kehu-num {
  width: 40%;
}
.team-activity .ceremony .share-user .visit-user .visit-content .number-list .detail-title .time,
.team-activity .ceremony .share-user .visit-user .visit-content .number-list .detail-title .join-time {
  width: 60%;
}
.team-activity .ceremony .share-user .visit-user .visit-content .join-team {
  margin: 0.2rem 0.2rem 0 0.2rem;
  background: #F6432F;
  height: 1rem;
  text-align: center;
  border-radius: 0.1rem;
  line-height: 1rem;
  color: #fff;
}
.team-activity .ceremony .share-user .visit-user .visit-content .no-join {
  margin: 0 0.2rem 0 0;
  height: 1rem;
  line-height: 1rem;
  text-align: center;
  border-radius: 0.1rem;
  color: #999;
}
.team-activity .ceremony .guide-bg {
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  top: 0;
  left: 0;
  z-index: 1;
}
.team-activity .ceremony .guide-box {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 2;
}
.team-activity .ceremony .guide-box .guide-top {
  width: 4.5rem;
  height: 3.7rem;
  background: url("~@/assets/img_share@3x.png") no-repeat;
  background-size: 100%;
  margin-left: 2.58rem;
  margin-bottom: 2.58rem;
}
.team-activity .ceremony .guide-box .guide-btn {
  width: 2.6rem;
  height: 0.9rem;
}
.team-activity .login-entrys {
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.4);
  top: 0;
  left: 0;
  position: absolute;
}
