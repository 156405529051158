.border-none:before {
  display: none;
}
.border-none:after {
  display: none;
}
.flex-fix {
  -ms-flex: 1 1 0px;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  flex: 1;
  -webkit-flex-basis: 0px;
  flex-basis: 0px;
  width: 1%;
}
.hide-scrollbar::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.allcover {
  position: absolute;
  top: 0;
  right: 0;
}
.center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.ct {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.cl {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
.clear-fix {
  *zoom: 1;
}
.clear-fix:before,
.clear-fix:after {
  content: "";
  display: table;
  clear: both;
  overflow: hidden;
}
.dialog {
  width: 6rem;
  height: 4.7rem;
  background: #fff;
  position: fixed;
  top: 50%;
  left: 50%;
  margin: -2.2rem 0 0 -3rem;
  padding: 0.2rem;
  border-radius: 0.08rem;
}
.dialog .login-txt {
  color: #333;
  font-weight: bold;
}
.dialog .tip {
  margin-top: 0.2rem;
  color: #666;
  font-size: 0.24rem;
  line-height: 0.3rem;
}
.dialog .cell {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding-left: 0.52rem;
  padding-top: 0.4rem;
  padding-bottom: 0.3rem;
  border-bottom: 1px solid #e1e1e1;
}
.dialog .cell .icon {
  position: absolute;
  top: 0.44rem;
  left: 0;
  display: inline-block;
  width: 0.32rem;
  height: 0.32rem;
  z-index: 2;
}
.dialog .cell input {
  width: 100%;
  height: 0.4rem;
  font-size: 0.28rem;
  color: #333;
  line-height: 0.4rem;
}
.dialog .cell input::-webkit-input-placeholder {
  color: #b3b3b3;
  font-size: 0.24rem;
}
.dialog .cell .icon-mobile {
  width: 0.24rem;
  background-image: url('../../../../assets/icon_denglu_shouji@2x.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
@media (-webkit-min-device-pixel-ratio: 3), (min-device-pixel-ratio: 3) {
  .dialog .cell .icon-mobile {
    background-image: url('../../../../assets/icon_denglu_shouji@3x.png');
  }
}
.dialog .cell .icon-password {
  width: 0.28rem;
  background-image: url('../../../../assets/icon_denglu_mima@2x.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
@media (-webkit-min-device-pixel-ratio: 3), (min-device-pixel-ratio: 3) {
  .dialog .cell .icon-password {
    background-image: url('../../../../assets/icon_denglu_mima@3x.png');
  }
}
.dialog .login-btn {
  width: 100%;
  padding: 0.4rem 0 0.2rem 0;
  font-size: 0.24rem;
  overflow: hidden;
}
.dialog .login-btn .cencel {
  width: 1.2rem;
  height: 0.48rem;
  background-color: #f2f2f2;
  float: right;
  text-align: center;
  line-height: 0.48rem;
  border-radius: 0.08rem;
  margin-right: 0.2rem;
}
.dialog .login-btn .submit {
  background: #2E8FF4;
  color: #fff;
  width: 1.2rem;
  height: 0.48rem;
  float: right;
  text-align: center;
  line-height: 0.48rem;
  border-radius: 0.08rem;
}
