/**
 * Component:	Variables
 * Description:	Define all variables
*/
.yd-tab-box {
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}
.yd-tab-nav-nomal .yd-tab-nav {
  display: flex;
}
.yd-tab-nav-nomal .yd-tab-nav .yd-tab-nav-item {
  width: 1%;
  flex: 1;
}
.yd-tab-nav-scoll .yd-tab-nav .yd-tab-nav-item {
  padding: 0 20px;
  display: inline-block;
}
.yd-tab-nav {
  position: relative;
  z-index: 0;
}
.yd-tab-nav:after {
  height: 1px;
  position: absolute;
  z-index: 3;
  bottom: 0;
  left: 0;
  content: '';
  width: 100%;
  background-image: linear-gradient(0deg, currentColor 50%, transparent 50%);
}
.yd-tab-nav-item {
  text-align: center;
  height: 100%;
  display: block;
  position: relative;
}
.yd-tab-nav-item-label {
  color: inherit;
  display: inline-block;
  position: relative;
}
.yd-tab-nav .yd-tab-active {
  color: currentColor;
}
.yd-tab-nav .yd-tab-active:before {
  content: '';
  width: 70%;
  height: 2px;
  position: absolute;
  left: 50%;
  bottom: 0;
  margin-left: -35%;
  z-index: 4;
  background-color: currentColor;
}
.yd-tab-panel {
  position: relative;
  overflow: hidden;
}
.yd-tab-panel-item {
  width: 100%;
  position: absolute;
  top: 0;
  overflow: hidden;
  transform: translateX(-100%);
}
.yd-tab-panel-item.yd-tab-active {
  position: relative;
  transition: transform 0.15s;
  transform: translateX(0);
}
.yd-tab-panel-item.yd-tab-active ~ .yd-tab-panel-item {
  transform: translateX(100%);
}
.yd-tab-badge {
  position: absolute;
  top: 2px;
  z-index: 9;
  line-height: 0;
}
.yd-tab-dot {
  position: absolute;
  top: -10px;
}
.yd-tab-dot > i {
  display: inline-block;
  width: 6px;
  height: 6px;
  border-radius: 100px;
}
