.border-none[data-v-70951dad]:before {
  display: none;
}
.border-none[data-v-70951dad]:after {
  display: none;
}
.flex-fix[data-v-70951dad] {
  -ms-flex: 1 1 0px;
  -webkit-box-flex: 1;
  flex: 1;
  -ms-flex-preferred-size: 0px;
      flex-basis: 0px;
  width: 1%;
}
.hide-scrollbar[data-v-70951dad]::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.allcover[data-v-70951dad] {
  position: absolute;
  top: 0;
  right: 0;
}
.center[data-v-70951dad] {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.ct[data-v-70951dad] {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.cl[data-v-70951dad] {
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}
.clear-fix[data-v-70951dad] {
  *zoom: 1;
}
.clear-fix[data-v-70951dad]:before,
.clear-fix[data-v-70951dad]:after {
  content: "";
  display: table;
  clear: both;
  overflow: hidden;
}
.team-activity[data-v-70951dad] {
  width: 100%;
  height: 100%;
  position: relative;
}
.team-activity .activity-tip[data-v-70951dad] {
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  color: #999;
}
.team-activity .home[data-v-70951dad] {
  position: fixed;
  top: 0.2rem;
  right: 0.2rem;
}
.team-activity .ceremony[data-v-70951dad] {
  width: 100%;
  height: 100%;
  font-size: 0.28rem;
  color: #333;
  background-size: 100% auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  letter-spacing: 0.02rem;
  /*引导分享*/
}
.team-activity .ceremony .process[data-v-70951dad] {
  width: calc(100% - 0.2rem);
  height: 7.4rem;
  padding-top: 1rem;
}
.team-activity .ceremony .process .process-txt[data-v-70951dad] {
  padding: 0 0.6rem;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  background: #F6432F;
  border-radius: 0.16rem;
}
.team-activity .ceremony .process .process-txt .process-one[data-v-70951dad] {
  width: 1.2rem;
  font-size: 0.28rem;
  color: #fff;
  text-align: center;
}
.team-activity .ceremony .process .process-txt .process-one .img[data-v-70951dad] {
  width: 0.8rem;
  height: 0.8rem;
  margin-bottom: 0.08rem;
}
.team-activity .ceremony .process .ceremony-content[data-v-70951dad] {
  border-radius: 0.16rem;
  overflow: hidden;
}
.team-activity .ceremony .process .ceremony-content .yd-tab[data-v-70951dad] {
  background: #fff;
}
.team-activity .ceremony .process .ceremony-content .yd-tab[data-v-70951dad] .yd-tab-active {
  color: #F6432F !important;
  background: #fff;
}
.team-activity .ceremony .process .ceremony-content .yd-tab[data-v-70951dad] .yd-tab-active::before {
  display: none;
}
.team-activity .ceremony .process .ceremony-content .yd-tab .yd-tab-panel-item[data-v-70951dad] {
  height: 6rem;
}
.team-activity .ceremony .process .ceremony-content .yd-tab .yd-tab-panel-item .team-border[data-v-70951dad] {
  margin: 0.2rem 0;
  height: 5.5rem;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}
.team-activity .ceremony .process .ceremony-content .yd-tab .yd-tab-panel-item .team-prize[data-v-70951dad] {
  margin: 0.3rem 0.2rem 0 0.4rem;
  height: 1.64rem;
  background: url('~@/assets/haiyuansu/prize_bg.png');
  position: relative;
  background-size: 100% 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.team-activity .ceremony .process .ceremony-content .yd-tab .yd-tab-panel-item .team-prize .img[data-v-70951dad] {
  margin-left: 0.36rem;
  width: 1.44rem;
  height: 1.44rem;
}
.team-activity .ceremony .process .ceremony-content .yd-tab .yd-tab-panel-item .team-prize .img img[data-v-70951dad] {
  width: 1.44rem;
  height: 1.44rem;
  display: block;
}
.team-activity .ceremony .process .ceremony-content .yd-tab .yd-tab-panel-item .team-prize .claim[data-v-70951dad] {
  font-size: 0.28rem;
  color: #333;
  width: 2.48rem;
  height: 1.44rem;
  margin-left: 0.7rem;
  position: relative;
}
.team-activity .ceremony .process .ceremony-content .yd-tab .yd-tab-panel-item .team-prize .claim .clain-con[data-v-70951dad] {
  font-size: 0.24rem;
  margin-top: 0.2rem;
}
.team-activity .ceremony .process .ceremony-content .yd-tab .yd-tab-panel-item .team-prize .claim .clain-con .number[data-v-70951dad] {
  color: #F6432F;
  font-size: 0.32rem;
  font-weight: bolder;
}
.team-activity .ceremony .process .ceremony-content .yd-tab .yd-tab-panel-item .team-prize .claim .team-user[data-v-70951dad] {
  font-size: 0.24rem;
  color: #F6432F;
  position: absolute;
  bottom: 0;
}
.team-activity .ceremony .process .ceremony-content .yd-tab .yd-tab-panel-item .team-prize .claim .team-user .schedule[data-v-70951dad] {
  width: 2.3rem;
  height: 0.06rem;
  background: #ccc;
  margin-top: 0.06rem;
  border-radius: 10%;
}
.team-activity .ceremony .process .ceremony-content .yd-tab .yd-tab-panel-item .team-prize .claim .team-user .schedule .actually-sch[data-v-70951dad] {
  height: 100%;
  background: #F6432F;
}
.team-activity .ceremony .process .ceremony-content .yd-tab .yd-tab-panel-item .team-prize .claim .prize-name[data-v-70951dad] {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.team-activity .ceremony .process .ceremony-content .yd-tab .yd-tab-panel-item .team-prize .btn .btn-layout[data-v-70951dad] {
  width: 1.44rem;
  height: 0.6rem;
  font-size: 0.24rem;
  color: #fff;
  border-radius: 0.64rem;
  background: #F6432F;
  line-height: 0.6rem;
  text-align: center;
}
.team-activity .ceremony .process .ceremony-content .yd-tab .yd-tab-panel-item .team-prize .btn .disabled[data-v-70951dad] {
  background: #ccc;
}
.team-activity .ceremony .process .ceremony-content .yd-tab .yd-tab-panel-item .team-prize .btn .failure[data-v-70951dad] {
  background: #ccc;
}
.team-activity .ceremony .process .ceremony-content .yd-tab .yd-tab-panel-item .team-prize .end[data-v-70951dad] {
  height: 100%;
  width: 1.6rem;
  text-align: right;
}
.team-activity .ceremony .process .ceremony-content .yd-tab .yd-tab-panel-item .team-prize .end .team-end[data-v-70951dad] {
  margin-top: 0.1rem;
}
.team-activity .ceremony .process .ceremony-content .yd-tab .yd-tab-panel-item .team-prize .end .team-end img[data-v-70951dad] {
  width: 0.8rem;
  height: 0.8rem;
}
.team-activity .ceremony .process .ceremony-content .yd-tab .yd-tab-panel-item .team-prize .end .team-end .detail-team[data-v-70951dad] {
  font-size: 0.24rem;
  color: #ccc;
  text-decoration: underline;
  margin-top: 0.3rem;
}
.team-activity .ceremony .process .ceremony-content .yd-tab .yd-tab-panel-item .team-prize .border-icon[data-v-70951dad] {
  width: 1.4rem;
  height: 0.74rem;
  font-size: 0.28rem;
  color: #fff;
  position: absolute;
  top: 0;
  left: -0.18rem;
  background: url('~@/assets/haiyuansu/team_icon.png');
  background-size: 100% 100%;
  text-align: center;
  line-height: 0.6rem;
}
.team-activity .ceremony .process .ceremony-content .yd-tab .yd-tab-panel-item .team-prize .border-icon img[data-v-70951dad] {
  width: 1.2rem;
  height: 0.74rem;
}
.team-activity .ceremony .process .ceremony-content .yd-tab .yd-tab-panel-item .my-team[data-v-70951dad] {
  width: calc(100% - 0.2rem);
  height: 0.6rem;
  position: relative;
  left: 0.1rem;
}
.team-activity .ceremony .process .ceremony-content .yd-tab .yd-tab-panel-item .my-team .eqit[data-v-70951dad] {
  font-size: 0.24rem;
  color: #999;
  position: absolute;
  right: 0;
  bottom: 0;
}
.team-activity .ceremony .process .ceremony-content .yd-tab .yd-tab-panel-item .my-team .team-num[data-v-70951dad] {
  width: 4rem;
  height: 0.3rem;
  position: absolute;
  text-align: center;
  left: 50%;
  top: 50%;
  margin: -0.15rem 0 0 -2rem;
  font-size: 0.28rem;
  color: #F6432F;
  line-height: 0.6rem;
}
.team-activity .ceremony .process .ceremony-content .yd-tab .yd-tab-panel-item .no-team[data-v-70951dad] {
  width: 100%;
  height: 5.72rem;
  line-height: 5.72rem;
  text-align: center;
  color: #999;
}
.team-activity .ceremony .process .ceremony-content .yd-tab .yd-tab-panel-item .detail[data-v-70951dad] {
  margin: 0.2rem;
  height: 4.5rem;
  border: 0.02rem dashed #f6432f;
  overflow: auto;
  border-radius: 0.08rem;
}
.team-activity .ceremony .process .ceremony-content .yd-tab .yd-tab-panel-item .detail .detail-title[data-v-70951dad] {
  margin-top: 0.2rem;
  overflow: hidden;
}
.team-activity .ceremony .process .ceremony-content .yd-tab .yd-tab-panel-item .detail .detail-title .common[data-v-70951dad] {
  float: left;
  text-align: center;
  font-size: 0.28rem;
  color: #333;
}
.team-activity .ceremony .process .ceremony-content .yd-tab .yd-tab-panel-item .detail .detail-title .mobile[data-v-70951dad],
.team-activity .ceremony .process .ceremony-content .yd-tab .yd-tab-panel-item .detail .detail-title .time[data-v-70951dad] {
  color: #999;
}
.team-activity .ceremony .process .ceremony-content .yd-tab .yd-tab-panel-item .detail .detail-title .mobile[data-v-70951dad],
.team-activity .ceremony .process .ceremony-content .yd-tab .yd-tab-panel-item .detail .detail-title .kehu-num[data-v-70951dad] {
  width: 40%;
}
.team-activity .ceremony .process .ceremony-content .yd-tab .yd-tab-panel-item .detail .detail-title .time[data-v-70951dad],
.team-activity .ceremony .process .ceremony-content .yd-tab .yd-tab-panel-item .detail .detail-title .join-time[data-v-70951dad] {
  width: 60%;
}
.team-activity .ceremony .process .ceremony-content .yd-tab .yd-tab-panel-item .active-rule[data-v-70951dad] {
  margin: 0 0.2rem;
}
.team-activity .ceremony .process .ceremony-content .yd-tab .yd-tab-panel-item .active-rule .active-time p[data-v-70951dad] {
  margin-top: 0.3rem;
}
.team-activity .ceremony .process .ceremony-content .yd-tab .yd-tab-panel-item .active-rule .active-time .time-detail[data-v-70951dad] {
  color: #333;
  overflow: auto;
}
.team-activity .ceremony .process .ceremony-content .yd-tab .yd-tab-panel-item .active-rule .active-time .time-num[data-v-70951dad] {
  color: #333;
}
.team-activity .ceremony .process .ceremony-content .yd-tab .yd-tab-panel-item .active-rule .active-time .rule-detail[data-v-70951dad] {
  width: 100%;
  height: 3.5rem;
  border: 0.02rem dashed #f6432f;
  margin-top: 0.2rem;
  padding: 0.2rem;
  border-radius: 0.08rem;
}
.team-activity .ceremony .process .ceremony-content .yd-tab .yd-tab-panel-item .active-rule .active-time .rule-detail .rule-txt[data-v-70951dad] {
  margin: 0;
  overflow: hidden;
  letter-spacing: 0.02rem;
  line-height: 0.4rem;
  color: #999;
  word-break: normal;
}
.team-activity .ceremony .visited-btn[data-v-70951dad] {
  width: 100%;
  height: 0.86rem;
  font-size: 0.28rem;
  color: #fff;
  text-align: center;
  line-height: 0.86rem;
  background: #F6432F;
  position: fixed;
  bottom: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.team-activity .ceremony .visited-btn .btn-con[data-v-70951dad] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.team-activity .ceremony .visited-btn .btn-con img[data-v-70951dad] {
  width: 0.44rem;
  height: 0.44rem;
  margin-right: 0.2rem;
}
.team-activity .ceremony .no-click[data-v-70951dad] {
  background: #ccc;
}
.team-activity .ceremony .share-user[data-v-70951dad] {
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 0;
  left: 0;
}
.team-activity .ceremony .share-user .visit-user[data-v-70951dad] {
  width: 6rem;
  height: 8rem;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -4.5rem 0 0 -3rem;
  background: #fff;
  border-radius: 0.1rem;
}
.team-activity .ceremony .share-user .visit-user .visit-content[data-v-70951dad] {
  margin: 0.2rem;
  text-align: center;
}
.team-activity .ceremony .share-user .visit-user .visit-content .visit-title[data-v-70951dad] {
  line-height: 0.5rem;
}
.team-activity .ceremony .share-user .visit-user .visit-content .number-list[data-v-70951dad] {
  width: 100%;
  height: 4rem;
  border: 0.02rem dashed #f6432f;
  border-radius: 0.2rem;
  overflow: auto;
}
.team-activity .ceremony .share-user .visit-user .visit-content .number-list .detail-title[data-v-70951dad] {
  margin-top: 0.2rem;
  overflow: hidden;
}
.team-activity .ceremony .share-user .visit-user .visit-content .number-list .detail-title .common[data-v-70951dad] {
  float: left;
  text-align: center;
  font-size: 0.28rem;
  color: #333;
}
.team-activity .ceremony .share-user .visit-user .visit-content .number-list .detail-title .mobile[data-v-70951dad],
.team-activity .ceremony .share-user .visit-user .visit-content .number-list .detail-title .time[data-v-70951dad] {
  color: #999;
}
.team-activity .ceremony .share-user .visit-user .visit-content .number-list .detail-title .mobile[data-v-70951dad],
.team-activity .ceremony .share-user .visit-user .visit-content .number-list .detail-title .kehu-num[data-v-70951dad] {
  width: 40%;
}
.team-activity .ceremony .share-user .visit-user .visit-content .number-list .detail-title .time[data-v-70951dad],
.team-activity .ceremony .share-user .visit-user .visit-content .number-list .detail-title .join-time[data-v-70951dad] {
  width: 60%;
}
.team-activity .ceremony .share-user .visit-user .visit-content .join-team[data-v-70951dad] {
  margin: 0.2rem 0.2rem 0 0.2rem;
  background: #F6432F;
  height: 1rem;
  text-align: center;
  border-radius: 0.1rem;
  line-height: 1rem;
  color: #fff;
}
.team-activity .ceremony .share-user .visit-user .visit-content .no-join[data-v-70951dad] {
  margin: 0 0.2rem 0 0;
  height: 1rem;
  line-height: 1rem;
  text-align: center;
  border-radius: 0.1rem;
  color: #999;
}
.team-activity .ceremony .guide-bg[data-v-70951dad] {
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  top: 0;
  left: 0;
  z-index: 1;
}
.team-activity .ceremony .guide-box[data-v-70951dad] {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 2;
}
.team-activity .ceremony .guide-box .guide-top[data-v-70951dad] {
  width: 4.5rem;
  height: 3.7rem;
  background: url("~@/assets/img_share@3x.png") no-repeat;
  background-size: 100%;
  margin-left: 2.58rem;
  margin-bottom: 2.58rem;
}
.team-activity .ceremony .guide-box .guide-btn[data-v-70951dad] {
  width: 2.6rem;
  height: 0.9rem;
}
.team-activity .login-entrys[data-v-70951dad] {
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.4);
  top: 0;
  left: 0;
  position: absolute;
}
